require('bootstrap');
require('dotenv').config();
console.log(process.env.FCM_PROJECTID);
var fcm_messaging;

function initFcm() {
    firebase.initializeApp({
        apiKey: process.env.FCM_API_KEY,
        authDomain: process.env.FCM_AUTH_DOMAIN,
        databaseURL: process.env.FCM_DATABASEURL,
        projectId: process.env.FCM_PROJECTID,
        storageBucket: process.env.FCM_STORAGE_BUCKET,
        messagingSenderId: process.env.FCM_MESSAGING_SENDER_ID,
        appId: process.env.FCM_APP_ID,
        measurementId: process.env.FCM_MEASUREMENT_ID
    });

    fcm_messaging = firebase.messaging();
    fcm_messaging.usePublicVapidKey(process.env.FCM_USE_PUBLIC_VAPI_KEY);
    fcm_messaging.onMessage((payload) => {
        setup_toast({
            body: payload.notification.body,
            head: payload.data.head,
            time: payload.data.time,
            link: payload.data.link,
            id  : payload.data.id
        });
        console.log('Message received. ', payload);
    });
}

function tokenRefreshFcm() {
    fcm_messaging.onTokenRefresh(() => {
        messaging.getToken().then((refreshedToken) => {
            console.log('Token refreshed.', refreshedToken);
        }).catch((err) => {
            console.log('Unable to retrieve refreshed token ', err);
        });
    });
}

async function getTokenFcm() {
    var token;

    await fcm_messaging.getToken().then((currentToken) => {
        if (currentToken) {
            console.log('Token New.', currentToken);
            token = currentToken
        } else {
            console.log('No Instance ID token available. Request permission to generate one.');
            token = false
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
    });

    return token
}

function subscribeTokenToTopic(token, topic) {
    fetch('https://iid.googleapis.com/iid/v1/' + token + '/rel/topics/' + topic, {
        method: 'POST',
        headers: new Headers({
            'Authorization': 'key=' + process.env.FCM_SERVER_KEY
        })
    }).then(response => {
        if (response.status < 200 || response.status >= 400) {
            throw 'Error subscribing to topic: ' + response.status + ' - ' + response.text();
        }
        console.log('Subscribed to "' + topic + '"');
    }).catch(error => {
        console.error(error);
    })
}

function setup_toast(data) {
    var id_toast = Math.floor(1000 + Math.random() * 9000);
    var audioUrl = 'bell.mp3';
    var current_counter = parseInt($('.unread-counter').html())
    
    $('.unread-counter').html(current_counter + 1)
    new Audio(audioUrl).play();

    $('.list-group').prepend(
        '<a href="' + data.link +'" class="notification-'+ data.id +' list-group-item list-group-item-action bg-secondary">' +
        '   <div class="row align-items-center">' +
        '       <div class="col">'+
        '           <div class="d-flex justify-content-between align-items-center">'+
        '               <div><h4 class="mb-0 text-sm">' + data.head + '</h4></div>'+
        '               <div class="text-right text-muted">'+
        '                   <small>' + data.time + '</small>'+
        '               </div>'+
        '           </div>'+
        '           <p class="text-sm mb-0">' + data.body + '</p>'+
        '       </div>'+
        '   </div>'+
        '</a>'
    )

    $('#notif_area').append(
        '<div id="id-' + id_toast + '" class="toast" role="alert" aria-live="assertive" data-autohide="false" aria-atomic="true">' +
        '  <div class="toast-header" style="background: rgb(213, 233, 180)">' +
        '    <span class="fas fa-bell" style="margin-right:10px"></span>' +
        '      <strong class="mr-auto">' + data.head + '</strong>' +
        '      <small class="text-muted" style="margin-left:10px">' + data.time + '</small>' +
        '      <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">' +
        '        <span aria-hidden="true">&times;</span>' +
        '      </button>' +
        '  </div>' +
        '    <div class="toast-body" style="min-width:180px">' +
        data.body +
        '  </div>' +
        '</div>'
    )
    $('#id-' + id_toast).toast('show');
}

function human_time(time) {
    let unix_timestamp = time
    var date = new Date(unix_timestamp * 1000);
    var hours = date.getHours();
    var minutes = "0" + date.getMinutes();
    var formattedTime = hours + ':' + minutes.substr(-2);
    return formattedTime
}

$(document).ready(async function () {
    //init firebase
    initFcm();

    var CURRENT_URL = window.location.pathname;
    var token = await getTokenFcm();
    if (CURRENT_URL == '/auth/administrators/sign_in'){
        $("#administrator_token_fcm").val(token);
    }else{
        subscribeTokenToTopic(token, 'admin-web');
    }
    console.log(token);
})