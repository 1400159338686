$(document).ready(function() {
  $('.toast').toast('hide');
  var CURRENT_URL = window.location.pathname.split('/')[1];
  $(".nav-item").find('a[href="/' + CURRENT_URL +'"]').parents('.collapse').addClass('show');

  $('input').iCheck({
    checkboxClass: 'icheckbox_flat-green',
    radioClass: 'iradio_flat-green'
  });

  $('.select2').select2()

  $('.datepicker').datepicker({
    format: 'dd/mm/yyyy',
    autoclose: true,
    todayHighlight: true,
    todayBtn: 'linked',
  });

  $('.selectAll').on('ifChecked', function (event) {
    $('.table-check').iCheck('check');
    triggeredByChild = false;
  });

  $('.selectAll').on('ifUnchecked', function (event) {
      if (!triggeredByChild) {
          $('.table-check').iCheck('uncheck');
      }
      triggeredByChild = false;
  });

  $('.table-check').on('ifUnchecked', function (event) {
      triggeredByChild = true;
      $('.selectAll').iCheck('uncheck');
  });

  $('.table-check').on('ifChecked', function (event) {
      if ($('.table-check').filter(':checked').length == $('.table-check').length) {
          $('.selectAll').iCheck('check');
      }
  });

  $('.file-input').on('change',function(){
    let fileName = $(this).val();
    $('.file-label').html(fileName);
  })

  $(".filter-vendor-product-selection").select2( {
    dropdownParent: $("#filter-vendor-transactions"),
    placeholder: "Pilih Jenis Produk",
    allowClear: true
  });

  $(".filter-vendor-status-selection").select2( {
    dropdownParent: $("#filter-vendor-transactions"),
    placeholder: "Pilih Status",
    allowClear: true
  });

  $(".filter-second-vendor-product-selection").select2( {
    dropdownParent: $("#filter-second-vendor-transactions"),
    placeholder: "Pilih Jenis Produk",
    allowClear: true
  });

  $(".filter-second-vendor-status-selection").select2( {
    dropdownParent: $("#filter-second-vendor-transactions"),
    placeholder: "Pilih Status",
    allowClear: true
  });

  $(".filter-third-vendor-product-selection").select2( {
    dropdownParent: $("#filter-third-vendor-transactions"),
    placeholder: "Pilih Jenis Produk",
    allowClear: true
  });

  $(".filter-third-vendor-status-selection").select2( {
    dropdownParent: $("#filter-third-vendor-transactions"),
    placeholder: "Pilih Status",
    allowClear: true
  });
})

$(document).on("page:load",function() {
  $('input').iCheck({
    checkboxClass: 'icheckbox_flat-green',
    radioClass: 'iradio_flat-green'
  });

  $('.select2').select2()

  $('.datepicker').datepicker({
    format: 'dd/mm/yyyy',
    autoclose: true,
    todayHighlight: true,
    todayBtn: 'linked',
  });
});

$(document).on('nested:fieldAdded', function(event){
  var field = event.field; 
  var checkboxField = field.find('.boolean');

  checkboxField.iCheck({
    checkboxClass: 'icheckbox_flat-green',
    radioClass: 'iradio_flat-green'
  });
});
